export function getEnumKeys<T>(enm: T): Array<keyof T> {
  return Object.keys(enm) as Array<keyof T>
}

export function getEnumValues<T>(enm: T): Array<T[keyof T]> {
  return Object.values(enm)
}

// export function getEnumKeys<T extends object>(enm: T): Array<keyof T> {
//   return Object.keys(enm) as Array<keyof T>
// }

// export function getEnumValues<T extends object>(enm: T): Array<T[keyof T]> {
//   return Object.values(enm)
// }
