import client from './index'

import {
  ApiPaginationResponse,
  ApiResponse,
  PaginationParams,
} from '@/models/api'

import {
  CarProfile,
  CarProfileStatus,
  CarProfileUpdateableParams,
  InsuranceRequestsCreate,
  InsuranceRequestsReturn,
} from '@/models/carProfile'

import { GetVehiclesParams } from '@/store/vehicles'

export default {
  getVehicles(params: PaginationParams & GetVehiclesParams) {
    return client.get<ApiPaginationResponse<CarProfile>>('/car-profiles', {
      params,
    })
  },

  getVehicleDetails(id: string) {
    return client.get<ApiResponse<CarProfile>>(`/car-profiles/${id}`)
  },

  updateVehicleStatus(id: string, status: CarProfileStatus) {
    return client.put<ApiResponse<{ status: CarProfileStatus }>>(
      `/car-profiles/${id}/update-status`,
      { status }
    )
  },

  verifyPermit(id: string, verified: boolean) {
    return client.put(`/car-profiles/${id}/verify-papers`, {
      verified,
      name: 'ownership_permit',
    })
  },
  updateVehicle(id: string, params: CarProfileUpdateableParams) {
    return client.put<ApiResponse<CarProfile>>(`/car-profiles/${id}`, params)
  },

  createInsurance: (carProfileId: string, params: InsuranceRequestsCreate) => {
    return client.post<ApiResponse<InsuranceRequestsReturn>>(
      `/insurance/create-car-profile-insurance-request/${carProfileId}`,
      params
    )
  },

  cancelInsurance: (policyNumber: string) => {
    return client.post<ApiResponse<void>>('/insurance/cancel-policy', {
      policyNumber,
    })
  },

  updateInsurance: (
    insuranceId: string,
    params: Partial<InsuranceRequestsCreate>
  ) => {
    return client.put<ApiResponse<InsuranceRequestsReturn>>(
      `/insurance/update-car-profile-insurance-request/${insuranceId}`,
      params
    )
  },
}
